<template>
    <div id="floorsList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="floors"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="b&f_floorsListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <router-link :to="{ name: 'buildings' }" id="floors_backToBuildings" class="btn btn-outline-brand btn-bold btn-sm btn-icon-h kt-margin-l-10"> <i class="fa fa-angle-left"></i> {{ $t("b&f_backToBuildings") }} </router-link>
                                    <router-link v-if="!isEmptyList" :to="{ name: 'newFloor' }" id="floors_createNewFloorButton" class="btn btn-brand btn-bold btn-sm btn-icon-h kt-margin-l-10">
                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                        {{ $t("b&f_createNewFloor") }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="kt-portlet kt-portlet--height-fluid kt-portlet--mobile ">
                                        <div class="kt-portlet__head kt-portlet__head--lg kt-portlet__head--noborder kt-portlet__head--break-sm">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--success">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon id="Bound" points="0 0 24 0 24 24 0 24"></polygon>
                                                            <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
                                                            <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z" id="Path" fill="#000000" opacity="0.3"></path>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("b&f_floorsList") }}
                                                </h3>
                                            </div>
                                            <div v-if="!isEmptyList" class="kt-portlet__head-toolbar">
                                                <div class="kt-portlet__head-wrapper">
                                                    <div class="kt-portlet__head-toolbar">
                                                        <div class="row align-items-center">
                                                            <div class="col-md-12 kt-margin-b-20-tablet-and-mobile">
                                                                <div class="kt-input-icon kt-input-icon--left">
                                                                    <input type="text" :placeholder="$t('common_searchByName')" @keyup="onSearch" v-model="searchedValue" id="floors_searchInput" class="searchInputDatatable form-control form-control-sm width270" />
                                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                                        <span><i class="la la-search"></i></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--begin emptyList component-->
                                        <app-emptylist targetObjectName="FLOOR" mainTextKey="b&f_floorNotFound" subTextKey="b&f_floorNotFoundSubLabel" imgName="empty_floor.svg" v-bind:isDisplayed="isEmptyList"></app-emptylist>
                                        <!--end emptyList component-->
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin: Datatable -->
                                            <div class="kt-datatable" id="floors_datatable"></div>
                                            <!--end: Datatable -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "./../site/widgets/sitetitle.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            buildingId: this.$route.params.buildingId,
            currentBuildingFloorsTable: null,
            isEmptyList: false,
            searchedValue: ""
        };
    },
    created: function() {
        console.log("Component(Floors)::created() - called");
        if (this.createAnotherFloor) {
            this.$router.push({ name: "newFloor" });
        }
    },
    mounted: function() {
        console.log("Component(Floors)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(Floors)::destroyed() - called");
        var nextRoute = this.$router.currentRoute.name;
        if (nextRoute !== "newFloor" && nextRoute !== "editFloor") {
            this.resetBuildingsState();
        }
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentBuildingFloors: function() {
            console.log("Component(Floors)::watch(currentBuildingFloors) called");
            this.initKtDataTable();
        },
        user: function(user) {
            if (user) {
                console.log("Component(Floors)::watch(user) called with :", user);
                this.initKtDataTable();
            }
        },
        currentSite: function(site) {
            console.log("Component(Floors)::watch(currentSite) called");
            // Load user sites from backend and store the result in sites store.
            const payload = {
                siteId: this.siteId,
                buildingId: this.buildingId
            };
            this.getAllFloorsOfBuilding(payload);
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentBuildingFloors", "currentSite", "createAnotherFloor", "user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getAllFloorsOfBuilding", "showDeleteModal", "resetBuildingsState"]),
        onSearch() {
            if (this.currentBuildingFloorsTable) {
                this.currentBuildingFloorsTable.search(this.searchedValue, "name");
            }
        },
        onEditFloor: function(floorId) {
            this.$router.push({ name: "editFloor", params: { siteId: this.siteId, buildingId: this.buildingId, floorId: floorId } });
        },
        registerEventsOnSiteFloorsTable() {
            var self = this;
            $("#floors_datatable").on("kt-datatable--on-layout-updated", () => {
                $(".deleteFloorButton")
                    .off()
                    .on("click", function() {
                        let floorId = $(this).attr("data-floorid");
                        let floorName = $(this).attr("data-floorname");
                        console.log("Component(SiteFloors)::registerEventsOnFloorsTable(deleteFloorButton) clicked. FloorId = " + floorId);
                        self.showDeleteModal({ textContent: i18n.t("b&f_deleteFloorConfirmText", { floorName: floorName }), actionName: "DELETE_FLOOR", params: { siteId: self.siteId, buildingId: self.buildingId, floorId: floorId } });
                    });
                $(".editFloorButton")
                    .off()
                    .on("click", function() {
                        let floorId = $(this).attr("data-floorid");
                        console.log("Component(SiteFloors)::registerEventsOnFloorsTable(editFloorButton) clicked. FloorId = " + floorId);
                        // Hide and destroy element’s tooltip
                        $('[data-toggle="kt-tooltip"]').tooltip("dispose");
                        // Go to edit vue
                        self.onEditFloor(floorId);
                    });

                // Force width pager size
                setTimeout(() => {
                    $(".kt-datatable__pager-size").css("width", "80px");

                    // Init tooltips
                    $('[data-toggle="kt-tooltip"]').tooltip({ trigger: "hover" });
                }, 50);
            });
        },
        initKtDataTable() {
            var self = this;
            const options = {
                data: {
                    type: "local",
                    source: commonVueHelper.preventHtmlInjection(this.currentBuildingFloors),
                    pageSize: 5,
                    saveState: {
                        webstorage: false
                    }
                },
                layout: commonVueHelper.getLayoutOptions(),
                translate: commonVueHelper.getTranslateOptions(),
                toolbar: commonVueHelper.getToolbarOptions(),
                sortable: !0,
                pagination: !0,
                columns: [
                    {
                        field: "name",
                        title: i18n.t("common_name"),
                        textAlign: "center",
                        template: function(row) {
                            return '\
                                <div class="kt-user-card-v2">\
                                    <div class="kt-user-card-v2__details openResourceLink">\
                                        <a class="editFloorButton kt-link kt-user-card-v2__name" data-floorid="' + row.id + '" href="javascript:;">' + row.name + "</a>\
                                    </div>\
                                </div>\
                            ";
                        }
                    },
                    {
                        field: "updatedAt",
                        title: i18n.t("common_lastUpdateDate"),
                        textAlign: "center",
                        sortable: "desc",
                        template: function(row) {
                            if (row.updatedAt) {
                                return moment(row.updatedAt).format(commonVueHelper.getDateStringFormatFromLocale(moment().locale()));
                            }
                        }
                    },
                    {
                        field: "description",
                        title: "Description",
                        textAlign: "center"
                    },
                    {
                        field: "Actions",
                        title: i18n.t("common_actions"),
                        sortable: false,
                        width: 110,
                        overflow: "visible",
                        autoHide: false,
                        textAlign: "center",
                        template: function(row) {
                            if (self.currentSite.isInProduction) {
                                return (
                                    `
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_edit") +
                                        `" type="button" data-floorid="` +
                                        row.id +
                                        `" class="btn btn-outline-brand btn-sm btn-icon editFloorButton">
                                            <i class="la la-pencil"></i>
                                        </button>
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_unauthorizedAction") +
                                        `" type="button" data-floorid="` +
                                        row.id +
                                        `" data-floorname="` +
                                        row.name +
                                        `" class="btn btn-outline-danger btn-sm btn-icon disabled">
                                            <i class="la la-trash"></i>
                                        </button>
                                    `
                                );
                            } else {
                                return (
                                    `
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_edit") +
                                        `" type="button" data-floorid="` +
                                        row.id +
                                        `" class="btn btn-outline-brand btn-sm btn-icon editFloorButton">
                                            <i class="la la-pencil"></i>
                                        </button>
                                        <button data-toggle="kt-tooltip" data-placement="top" data-original-title="` +
                                        i18n.t("common_delete") +
                                        `" type="button" data-floorid="` +
                                        row.id +
                                        `" data-floorname="` +
                                        row.name +
                                        `" class="btn btn-outline-danger btn-sm btn-icon deleteFloorButton">
                                            <i class="la la-trash"></i>
                                        </button>
                                    `
                                );
                            }
                        }
                    }
                ]
            };

            // If site table has been already build, destroy the table before creating a fresh instance !
            if (this.currentBuildingFloorsTable) {
                $("#floors_datatable")
                    .KTDatatable()
                    .destroy();
            }

            // According to source list length, show datatable or empty list icon+text
            if (options.data.source && options.data.source.length > 0) {
                // There is data => Hide empty list icon+text
                this.isEmptyList = false;
                // Now, we are ready to build the table
                this.currentBuildingFloorsTable = $("#floors_datatable").KTDatatable(options);
                // Once table is build, register click events on buttons inside the table
                this.registerEventsOnSiteFloorsTable();
            } else {
                // There is no data => Show empty list icon+text
                this.isEmptyList = true;
            }
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
